import React from 'react'
import * as st from '~/assets/styl/404.module.styl'
import notFound from '~/assets/img/404.jpg'
import { Link } from 'gatsby'
import { LazyImage } from 'react-lazy-images'

const NotFound = () => (
  <>
    <div className={st.notFoundWrapper}>
      <section className={st.notFound}>
        <h2>Página não encontrada</h2>
        <p>
          A página que você tentou acessar não existe ou está fora do ar
          temporariamente.
        </p>
        <div className={st.buttonWrapper}>
          <Link to="/" className={st.back}>
            Ir para a home
          </Link>
          <Link to="/assinatura-de-veiculos/" className={st.back}>
            Simule seu veículo
          </Link>
        </div>
      </section>
      <div className={st.imageWrapper}>
        <LazyImage
          src={notFound}
          placeholder={({ ref }) => (
            <img
              ref={ref}
              src={notFound}
              alt="Mulher pensando em frente ao notebook"
            />
          )}
          actual={({ imageProps }) => <img {...imageProps} />}
        />
      </div>
    </div>
  </>
)

export default NotFound
